import React from 'react';

const ProgressBar = ({ currentStep, totalSteps, className }) => {
    return (
        <div className={`w-[20rem] mx-auto relative ${className}`}>
            <ol className="flex items-center w-full justify-center mb-4 mx-auto relative">
                {Array.from({ length: totalSteps }, (_, index) => totalSteps - index - 1).map((index) => (
                    <li
                        key={index}
                        className="relative flex-1 text-center"
                    >
                        {/* Adjust the line between the circles */}
                        {index > 0 && (
                            <div
                                className={`absolute top-1/2 left-0 transform -translate-y-1/2 w-full h-[1px] transition-all duration-300 ${index < currentStep
                                    ? 'bg-[#8641BD]'
                                    : 'bg-gray-300 dark:bg-gray-700'
                                    }`}
                                style={{ zIndex: -1 }}
                            ></div>
                        )}
                        <div className={`flex items-center justify-center w-6 h-6 rounded-full transition-colors duration-300 ${index < currentStep
                            ? 'bg-[#8641BD] shadow-lg'
                            : 'bg-gray-300 dark:bg-gray-700'
                            }`}>
                            <span
                                className={`text-xs font-medium transition-colors duration-300 ${index < currentStep
                                    ? 'text-white'
                                    : 'text-gray-500 dark:text-gray-300'
                                    }`}
                            >
                                {index + 1}
                            </span>
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default ProgressBar;
