'use client'
import { MdInfo } from "react-icons/md"

const Step2 = ({setCurrentStep}) => {
  return (
        <div className="flex flex-col w-full md:w-[600px] gap-3">
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
            <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
            قصتك الشخصية 🧍
            </span>
          <textarea  placeholder='ما قصتك مع هذه الفكرة؟ هل تتذكر حادثة شخصية أو تجربة مرت بك؟ اكتبها باستفاضة' className='w-full  rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
            <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
             مراجعك ومعلوماتك عن الفكرة 🧠
            </span>
          <textarea  placeholder='.هل لديك مصادر أو مراجع تعرفها لجمع معلومات عن الفكرة؟ روابط؟ كتب؟ مقاطع فيديو؟ ضعها هنا ' className='w-full       rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
        </div>
  )
}

export default Step2