
import { MdInfo } from "react-icons/md"
const Step3 = () => {
  
  return (
    <div className="flex flex-col w-full md:w-[600px] gap-3">
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
            <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
             القارئ👸
            </span>
          <textarea  placeholder='لمَن تكتب؟ مَن القارئ الذي يحتاج هذا النص؟
            طلاب الجامعة؟ محبو القراءة؟ الأمهات في سن الثلاثين؟ المهتمون بسياسات الدول الشرقيّة؟ حدّد قارئك لتنجح في الكتابة له' className='w-full  rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
            <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
             الهدف من كتابتك⛳
            </span>
          <textarea  placeholder='لماذا تريد الكتابة عن هذه الفكرة؟ كيف ستُحدث كتابتك التغيير لدى قارئها؟' className='w-full       rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
    </div>
  )
}

export default Step3