'use client'
import { MdInfo } from "react-icons/md"
import InputField from "@/components/InputField"
const Step1 = () => {
  return ( 
  <div className="flex flex-col -mt-5 w-full md:w-[600px] gap-3">
          <label className="flex flex-col gap-1 text-right">
            <span className="flex justify-end text-[14px] font-[500] gap-1 items-center">
              <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
              ما الموضوعات التي يدور حولها نصك؟📚
              </span>
            <InputField className={'w-full border-[1.5px] bg-[#fbfafa] text-right'}   
            placeholder={'في أيّ حقل أو مجال تنتمي فكرتك؟ العلوم؟ الفنون؟ الأعمال؟'} />
          </label>
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
            <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
              وما هي أول جملة تخطر ببالك عن النص؟ 💡</span>
          <textarea  placeholder='...مثال: پيكاسو غيّر مجرى الفن في زمنه حين بدأ بالرسم بطريقة مختلفة ' className='w-full  rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
          <label className="flex flex-col gap-1 text-right">
            <span className="flex justify-end text-[14px] font-[500] gap-1 items-center">
              <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
              لو كان عليك اختيار عنوان للنص، ماذا سيكون؟ 📰 </span>
            <InputField className={'w-full border-[1.5px] bg-[#fbfafa] text-right'}   
            placeholder={'...مثال: 5 لحظات غيّرت مجرى الفن عبر العصور'} />
          </label>
  </div>
  )
}

export default Step1