import { MdInfo } from "react-icons/md"

const Step5 = () => {
  const options =[
    'مقال',
    'قصة',
    'قصيدة',
    'تدوينة',
    'منشور اجتماعي'
  ]
  return (
    <div className="flex flex-col  w-full md:w-[600px] gap-8">
         <label className="space-y-2 w-full">
            <span className="flex gap-1 text-[#09090B] text-right text-[14px] font-[500] items-center justify-end">
              <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
              ما القالب الذي تريد استخدامه في الكتابة؟ 📒
            </span>
            <select className="p-2 text-[16px] text-right cursor-pointer bg-[#FBFAFA] placeholder:text-[#CBCBCB] font-[500] rounded-md border-[1.5px] outline-none w-full ">
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 text-right">
            <span className="flex text-[14px] font-[500] justify-end gap-1 items-center">
              <MdInfo className="cursor-pointer" color="#DFDFDF" size={15}/>
              ما الأسلوب الذي تريد إظهاره في كتابتك؟ 🧙 
            </span>
          <textarea  placeholder='.ما هي الشخصيّة التي تريد إظهارها في كتابتك؟ لا تختبئ خلف الكلمات المنمّقة وتحدث مع القارئ بوضوح وصدق ' className='w-full  rounded-md p-2 outline-none text-right bg-[#fbfafa] border-[1.5px] h-[100px]' />
          </label>
    </div>
  )
}

export default Step5