'use client'
import { useState } from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Image from "next/image"
import Modal from 'react-modal'
import ProgressBar from "./ProgressBar"
import { backIcon, cloud, generateIdea } from '../../../../../public/images'
import { Button } from '@/components/ui/button'
import { useRouter } from 'next/navigation'

const GenerateIdea = () => {
    const [currentStep,setCurrentStep] = useState(1)
    
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',  
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        // maxWidth: '400px',
        height: "100%",
        background: "white",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    }}  
    const router = useRouter()
    
  return (
    <Modal isOpen={true} onRequestClose={()=>router.push("/dashboard/explore")} style={customStyles}>
      <div className="flex relative h-full  flex-col gap-6 items-center ">
        <Image src={cloud} alt="cloud" width={20} height={20} />
        <span onClick={()=>router.push("/dashboard/explore")} className="border cursor-pointer absolute md:top-14 top-0 right-2 sm:right-5 md:right-10 border-[#E4E4E7] flex gap-1 bg-[#F8F8F8]  px-2 py-1 text-[10px] font-[500] rounded-md">
           العودة  
          <Image  src={backIcon} alt="profile" width={10} height={10}/>
        </span>
        <span className="flex gap-2 sm:flex-row flex-col  items-center">
          <p className="md:text-[20px] text-[16px] font-[500] text-[#8641BD]">سأساعدك على تحويل أفكارك المبعثرة إلى نص مُذهل ومؤثر.</p>
          <Image src={generateIdea} alt="" width={50} height={50} />
        </span>
        <ProgressBar totalSteps={5} currentStep={currentStep} />      
        {currentStep === 1 && <Step1 isOpen={true} setCurrentStep={setCurrentStep} currentStep={currentStep} />}
        {currentStep === 2 && <Step2  isOpen={true} setCurrentStep={setCurrentStep} currentStep={currentStep} />}
        {currentStep === 3 && <Step3  isOpen={true} setCurrentStep={setCurrentStep} currentStep={currentStep}/>}
        {currentStep === 4 && <Step4  isOpen={true} setCurrentStep={setCurrentStep} currentStep={currentStep}/>}
        {currentStep === 5 && <Step5 isOpen={true} setCurrentStep={setCurrentStep} currentStep={currentStep} />}
        <div className="w-full fixed bottom-0 pb-5 md:w-[600px]">
        { currentStep === 2  && <span className="flex mt-2   justify-between   items-center">
              <span className="flex gap-2">
              <Button onClick={() => setCurrentStep(currentStep + 1)} className='bg-[#8641BD] text-white '>التالي</Button>
              <Button onClick={() => setCurrentStep(currentStep - 1)} className='text-[#4D5861] bg-white shadow-md'>السابق</Button>
              </span>
              <Button className='text-[#8641BD] bg-white shadow-md' >الانتقال إلى المحرر</Button>
        </span>}
        { currentStep === 3  && <span className="flex mt-2 justify-between items-center">
              <span className="flex gap-2">
              <Button onClick={() => setCurrentStep(currentStep + 1)} className='bg-[#8641BD] text-white '>التالي</Button>
              <Button onClick={() => setCurrentStep(currentStep - 1)} className='text-[#4D5861] bg-white shadow-md'>السابق</Button>
              </span>
              <Button className='text-[#8641BD] bg-white shadow-md' >الانتقال إلى المحرر</Button>
        </span>}
        { currentStep === 4  && <span className="flex mt-2 justify-between  items-center">
              <span className="flex gap-2">
              <Button onClick={() => setCurrentStep(currentStep + 1)} className='bg-[#8641BD] text-white '>التالي</Button>
              <Button onClick={() => setCurrentStep(currentStep - 1)} className='text-[#4D5861] bg-white shadow-md'>السابق</Button>
              </span>
              <Button className='text-[#8641BD] bg-white shadow-md' >الانتقال إلى المحرر</Button>
        </span>}
        {currentStep === 1 &&
          <span className="flex justify-between  w-full items-center">
            <Button onClick={() => setCurrentStep(currentStep + 1)} className='bg-[#8641BD] text-white shadow-md'>التالي</Button>
            <Button onClick={() => router.push('/text-editor')} className='text-[#8641BD] bg-white shadow-md' >الانتقال إلى المحرر</Button>
          </span>
        }
        { currentStep === 5 && 
          <span className="flex mt-2 gap-2  items-center">
              <Button onClick={() =>router.push('/text-editor')} className='bg-[#8641BD] text-white '>التالي</Button>
              <Button onClick={() => setCurrentStep(currentStep - 1)} className='text-[#4D5861] bg-white shadow-md'>السابق</Button>
          </span> }
        </div>
        </div>
    </Modal>
  )
}

export default GenerateIdea