"use client";

import { AsteriskIcon, Eye, EyeOff } from "lucide-react";
import Link from "next/link";
import PropTypes from "prop-types";
import { useState } from "react";
import { MdInfo } from "react-icons/md";

const InputField = ({
    label,
    placeholder,
    type,
    forgotPassword = false,
    className,
    name,
    defaultValue,
    register,
    icon,
    asterisk = false,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={`flex flex-col text-right gap-y-2 w-full `}>
            <div className="flex justify-between items-center">
                <span className="flex  text-right justify-between items-center  w-full gap-x-1">
                    <label htmlFor={name} className="text-[#09090B]  w-full text-right text-[14px] font-[500]">
                        {label}
                    </label>
                    {asterisk && <AsteriskIcon className="w-3 text-[#8641BD]" />}
                    {icon && <MdInfo color="#DFDFDF" className="cursor-pointer" size={20}/>}
                </span>
                {forgotPassword ? (
                    <Link
                        href="/forgot-password"
                        className="text-right text-black text-xs font-normal"
                    >
                        نسيت كلمة السر؟
                    </Link>

                ) : (
                    ""
                )}
            </div>
            <div className="relative">
                <input
                    type={type === "password" && showPassword ? "text" : type}
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    className={`w-full border border-[#E4E4E7] text-right outline-none rounded-md outline-offset-4 px-4 py-2 text-base font-normal ${className}`}
                    {...(register && name && { ...register(name) })}
                    {...rest}
                />

                {type === "password" && (
                    <button
                        type="button"
                        className="absolute top-1/2 end-4 text-right transform -translate-y-1/2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <Eye className="w-4" /> : <EyeOff className="w-4" />}
                    </button>
                )}
            </div>
        </div>
    );
};

InputField.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    label: PropTypes.string,
    register: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.string,
    forgotPassword: PropTypes.bool,
    asterisk: PropTypes.bool,
};

export default InputField;
