import { useState } from "react";
import { MdInfo } from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";

const Step4 = () => {
  const options = [
    'كيف ستوصل رسالتك إلى القارئ؟',
    'دروس تعلمتها',
    'خطوات للإتقان',
    'قصص من حياتك',
    'قصص من حياة أشخاص آخرين',
    'نصائح إلى ذاتك السابقة',
    'مصاعب وعقبات تجاوزتها',
    'أرقام وإحصاءات وتفسيرات',
    'ظواهر تستحق الدراسة',
    'أمثلة وشواهد على رأي أو فكرة لديك',
    'أدوات مفيدة',
    'مهارات',
    'صفات',
    'عادات يومية',
    'أخطاء ينبغي تجنبها',
    'كتب اخترتها',
    'قصص اخترتها',
    'اقتباسات اخترتها',
    'أسئلة للتأمل أو للطرح',
    'حلقات بودكاست اخترتها',
    'استراتيجيات',
    'أهداف',
    'تحديات',
    'أسباب',
    'فوائد',
    'خرافات',
    'مصادر',
    'اختراعات',
    'إحصاءات',
    'شخصيات',
    'تأملات'
  ];

  const options2 = [
    { label: 'الراوي', value: 'الراوي' },
    { label: 'صاحب الرأي', value: 'صاحب الرأي' },
    { label: 'مثير الجدل', value: 'مثير الجدل' },
    { label: 'الخبير المُعلّم', value: 'الخبير المُعلّم' },
    { label: 'صاحب البيانات والإحصاءات', value: 'صاحب البيانات والإحصاءات' }
  ];

  const [selected, setSelected] = useState([]);
  // Debugging: Log selected options to verify state is updating
  console.log("Selected options:", selected);

return (
    <div className="flex flex-col w-full md:w-[600px] gap-8">
      {/* وسيلة الكتابة */}
      <label className="space-y-2 w-full">
        <span className="flex gap-1 text-[#09090B] text-right text-[14px] font-[500] items-center justify-end">
          <MdInfo className="cursor-pointer" color="#DFDFDF" size={15} />
          وسيلة الكتابة 🛠️
        </span>
        <select className="p-2 text-[16px] text-right cursor-pointer bg-[#FBFAFA] placeholder:text-[#CBCBCB] font-[500] rounded-md border-[1.5px] outline-none w-full">
          {options.map((option, index) => (
            <option className="px-2 cursor-pointer" key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>

      {/* صوتك الكتابي */}
      <label className="space-y-2 w-full">
        <span className="flex gap-1 text-[#09090B] text-right text-[14px] font-[500] items-center justify-end">
          <MdInfo className="cursor-pointer" color="#DFDFDF" size={15} />
          صوتك الكتابي 🗣
        </span>
        <MultiSelect
          disableSearch
          options={options2}
          value={selected}
          onChange={setSelected}
          labelledBy="اختر صوتك الكتابي"
          className="text-right outline-none"
          hasSelectAll={false}
          
          // Custom value renderer to show the selected options in the input field
          valueRenderer={(selected) => {
            if (selected.length === 0) {
              return <span className="text-[#CBCBCB]">كيف سيكون صوتك الكتابي في هذا النص؟</span>;
            }
            return selected.map(({ label }) => label).join(", ");  }}
          
          // Custom ItemRenderer to ensure correct handling of clicks
          ItemRenderer={({ option, checked, onClick }) => (
            <div onClick={onClick} className={`cursor-pointer flex gap-2 justify-end text-right w-full `} >
              <span className="text-[14px] ml-2">{option.label}</span>
              <input type="checkbox" checked={checked} readOnly />
            </div>
          )}
        />
      </label>
    </div>
  )
}

export default Step4